import { createRouter,  createWebHistory, createWebHashHistory, RouterView } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import S0View from '../views/svc/S0View.vue'
import S1View from '../views/svc/S1View.vue'
import S2View from '../views/svc/S2View.vue'
import S3View from '../views/svc/S3View.vue'
import S4View from '../views/svc/S4View.vue'
import S5View from '../views/svc/S5View.vue'
import S6View from '../views/svc/S6View.vue'

import P0View from '../views/proj/P0View.vue'
import P1View from '../views/proj/P1View.vue'
import P2View from '../views/proj/P2View.vue'
import P3View from '../views/proj/P3View.vue'
import P4View from '../views/proj/P4View.vue'
import P5View from '../views/proj/P5View.vue'
import P6View from '../views/proj/P6View.vue'
import P7View from '../views/proj/P7View.vue'
import P8View from '../views/proj/P8View.vue'
import P9View from '../views/proj/P9View.vue'
import P10View from '../views/proj/P10View.vue'
import P11View from '../views/proj/P11View.vue'
import P12View from '../views/proj/P12View.vue'

const routes = [
  
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  },
  
  {
    path: '/svc',
    name: 'svc',
    component: S0View,
    children: [
      {
          path: 's1',
          component: S1View,
      }, 
      {
        path: 's2',
        component: S2View,
      }, 
      {
        path: 's3',
        component: S3View,
      }, 
      {
        path: 's4',
        component: S4View,
      }, 
      {
        path: 's5',
        component: S5View,
      }, 
      {
        path: 's6',
        component: S6View,
      }, 
    ],
  },

  {
    path: '/proj',
    name: 'proj',
    component: P0View,
    children: [
      {
          path: 'p1',
          component:  P1View,
      },
      {
        path: 'p2',
        component:  P2View,
      },
      {
        path: 'p3',
        component:  P3View,
      } ,
      {
        path: 'p4',
        component: P4View,
      },
      {
        path: 'p5',
        component:  P5View,
      },
      {
        path: 'p6',
        component: P6View,
      },
      {
        path: 'p7',
        component: P7View,
      },
      {
        path: 'p8',
        component: P8View,
      },
      {
        path: 'p9',
        component: P9View,
      },
      {
        path: 'p10',
        component: P10View,
      },
      {
        path: 'p11',
        component: P11View,
      },
      {
        path: 'p12',
        component: P12View,
      }
 
    ],
  },
 
 
  // {
  //   path: '/account',
  //   name: 'account',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AccountView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

export default router
